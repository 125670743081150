import React, { useState, useEffect } from "react";
import './TenantAccountLogin.css'
import { useAuth } from "../../../authGuard/AuthGuard";
import { SubmitHandler, useForm } from 'react-hook-form';
import * as coreService from "../../../services/coreService";
import * as toastrService from "../../../services/toastrService";
import ApiLoader from "../../../components/apiLoader/ApiLoader";
import { Select } from "antd";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Controller } from "react-hook-form";

type FormValues = {
    email: string;
    password: string;
    tenant: string;
};

interface TenantData {
    id: string;
    company_name: string;
};

export default function TenantAccountLogin() {
    let auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormValues>();
    const [searchParams, setSearchParams] = useSearchParams();
    const returnUrl = searchParams.get("returnUrl") || "/";
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('');
    const [tenantData, setTenantData] = useState<TenantData[]>([]);

    useEffect(() => {
        let storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            setUserEmail(storedEmail);
            setLoading(true);
            const data = new FormData();
            data.append('email', storedEmail)
            setValue('email', storedEmail);
            coreService
                .post("login_check", data)
                .then((res: any) => {
                    setTenantData(res.data.data);
                    setLoading(false);
                    if (res.data.data.length === 1) {
                        setValue('tenant', res.data.data[0].id);
                    }
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastrService.error(err?.response?.data?.message);
                });
        }
    }, [setValue])

    function showPasswosrd() {
        setIsShowPassword(!isShowPassword);
    }

    const handleTenantChange = (value: string) => {
        setValue('tenant', value);
    }

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        if (!data.email || !data.password || !data.tenant) {
            return;
        }

        setLoading(true);
        coreService
            .post("login", data)
            .then((res: any) => {
                setLoading(false);
                auth.setToLocal(res?.data?.access_token, res?.data?.data?.tenant_id);
                auth.setUser(res?.data?.data);
                localStorage.setItem("$u$er", JSON.stringify(res?.data?.data));
                navigate(returnUrl, { replace: true });
            })
            .catch((err: any) => {
                setLoading(false);
                toastrService.error(err?.response?.data?.message);
            });
    }

    return (
        <>
            <form
                autoComplete="off"
                className="auth-form d-grid align-items-center h-100 p-3 p-sm-4 p-xl-5"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <div className="w-100">
                    <h2 className="gilroy-bold">Sign In</h2>
                    {tenantData.length === 1 ? 
                    (<>
                        <p className="gilroy-medium text-capitalize">Your Company - {tenantData[0].company_name}</p>
                    </>) 
                    : 
                    (<>
                        <p className="gilroy-medium">Please Select Your Company</p>
                    </>)}
                    <div className="relative py-1">
                        <label htmlFor="email">
                            Email
                        </label>
                        <input
                            value={userEmail}
                            type="email"
                            className={`w-100 p-2 mt-1 form-control ${errors.email ? "error" : ""}`}
                            readOnly
                            name="email"
                        />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="tenant">
                            Select Company <span className="required">*</span>
                        </label>
                        <Select
                            id="tenant"
                            className="w-100 mt-1 mb-2"
                            size="large"
                            placeholder="Select Company"
                            onChange={handleTenantChange}
                            value={tenantData.length === 1 ? tenantData[0].id : undefined}
                            disabled={tenantData.length === 1}
                        >
                            {tenantData.map((option, i) => (
                                <Select.Option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.company_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="relative py-1">
                        <label htmlFor="password">
                            Password <span className="required">*</span>
                        </label>
                        <input
                            {...register("password", { required: true,
                            })}
                            type={!isShowPassword ? "password" : "text"}
                            className={`w-100 p-2 mt-1 form-control ${errors.password ? "error" : ""}`}
                            placeholder="Password" name="password"
                        />
                        <span className="pass-eye" onClick={showPasswosrd}>
                            <img
                                src={
                                    isShowPassword
                                        ? "/assets/images/eye.svg"
                                        : "/assets/images/eye-slash.svg"
                                }
                                alt="eye"
                            />
                        </span>
                        {errors.password && <span className="required-error">{errors.password.message}</span>}
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
                        <div>
                            <NavLink className="forgot-pass" to={"/auth/forgot-password"}>
                                Forgot password?
                            </NavLink>
                        </div>
                    </div>
                    <button
                        disabled={loading}
                        className="btn btn-primary w-100 Gilroy-medium"
                        type="submit"
                    >
                        Sign In
                    </button>
                    <div className="sign-up mt-3 text-center">
                        If you don’t have an account.
                        <NavLink to={"/auth/sign-up"}> Create Account</NavLink>
                    </div>
                </div>
                {/* </div> */}
            </form>
            {loading ? <ApiLoader /> : ""}
        </>
    )
}