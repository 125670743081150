import "./ApiLoader.css";

function ApiLoader(){
    return (
        <>
        <div className="custom-loader-backdrop"></div>
        <div className="api-custom-loader"></div>
        </>
        )
}

export default ApiLoader;